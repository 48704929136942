import { useHistory } from "react-router";
import styles from "./ReportHeaderButtons.module.css";
import Button from "antd/es/button";
import { saveMap, forkReport, copyUrlToClipboard } from "./actions";
import {
  CopyOutlined,
  SaveOutlined,
  PlaySquareOutlined,
  EditOutlined,
  ConsoleSqlOutlined,
  ForkOutlined,
  LogoutOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { Reset } from "kepler.gl/dist/components/common/icons";
import { Tooltip } from "kepler.gl/dist/components/common/styled-components";
import { FormattedMessage } from "kepler.gl/dist/localization";
import Tippy, { TippyProps } from "@tippyjs/react";
import styled, { ThemeProvider } from "styled-components";
import React, { useEffect, useState, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelQuery, queryChanged, runQuery, showDataTable } from "./actions";
import { setFilter } from "kepler.gl/dist/actions/";
import { getGlobal } from "./globals";

const TippyArrow = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  fill: ${(props) => props.theme.tooltipBg};
  text-align: initial;

  > svg {
    position: absolute;
  }
`;

const TippyTooltipContent = styled(({ children, ...props }) => (
  <div {...props}>
    {children}
    <TippyArrow className="svg-arrow" data-popper-arrow="">
      <svg width={14} height={14}>
        <path fill="hsl(220, 3%, 34%)" d="M2,7.5 7.5,2 13,7.5Z" />
      </svg>
    </TippyArrow>
  </div>
))`
  background-color: hsl(220, 3%, 34%);
  color: #ffffff;
  font-size: 10px;
  font-weight: 400;
  padding: 7px 18px;
  box-shadow: 0 1px 2px 0rgba (0, 0, 0, 0.1);
  border-radius: 3px;
  &[data-placement^="top"] > .svg-arrow {
    bottom: 0;
    &::after,
    > svg {
      top: 7px;
      transform: rotate(180deg);
    }
  }

  &[data-placement^="bottom"] > .svg-arrow {
    top: 0;
    > svg {
      bottom: 7px;
    }
  }

  &[data-placement^="left"] > .svg-arrow {
    right: 0;
    &::after,
    > svg {
      transform: rotate(90deg);
      left: 7px;
    }
  }

  &[data-placement^="right"] > .svg-arrow {
    left: 0;
    &::after,
    > svg {
      transform: rotate(-90deg);
      right: 7px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: inherit;
  padding: 0;
  .button.clear-all {
    background: transparent;
    color: ${(props) => props.theme.subtextColor};
    margin: 0 0 0 8px;
    padding: 0;
    &:hover {
      color: ${(props) => props.theme.textColor};
    }
  }
`;

const TippyTooltip = ({
  children,
  render,
  duration = 200,
  ...rest
}: TippyProps) => {
  const [opacity, setOpacity] = useState(0);
  const [timer, setTimer] = useState(null);
  function onMount() {
    setOpacity(1);
    if (timer) {
      // @ts-ignore
      clearTimeout(timer);
    }
  }

  function onHide(instance) {
    const { unmount } = instance;
    const timeout = setTimeout(() => {
      if (!instance.state?.isDestroyed) {
        unmount();
      }
    }, duration[0] || duration);
    // @ts-ignore
    setTimer(timeout);
    setOpacity(0);
  }

  return (
    <Tippy
      {...rest}
      animation={true}
      render={(attrs) => (
        <TippyTooltipContent
          {...attrs}
          style={{ opacity, transition: `opacity ${duration}ms` }}
        >
          {render?.(attrs)}
        </TippyTooltipContent>
      )}
      onMount={onMount}
      onHide={onHide}
    >
      {children}
    </Tippy>
  );
};

function CopyLinkButton() {
  const dispatch = useDispatch();
  return (
    <Button
      type="text"
      icon={<CopyOutlined />}
      size="large"
      title="Copy link to report"
      onClick={() => dispatch(copyUrlToClipboard(window.location.toString()))}
    />
  );
}

function LogoutButton() {
  const dispatch = useDispatch();
  return (
    <Button
      type="text"
      icon={<LogoutOutlined />}
      size="large"
      title="Logout"
      onClick={() => {
        localStorage.removeItem("expire");
        localStorage.removeItem("token");
        window.location.reload();
      }}
    />
  );
}

function ForkButton({ reportId, disabled, primary }) {
  const dispatch = useDispatch();
  if (primary) {
    return (
      <Button
        type="primary"
        icon={<ForkOutlined />}
        disabled={disabled}
        onClick={() => dispatch(forkReport(reportId))}
      >
        Fork
      </Button>
    );
  }
  return (
    <Button
      type="text"
      icon={<ForkOutlined />}
      disabled={disabled}
      onClick={() => dispatch(forkReport(reportId))}
      title="Fork Report"
    />
  );
}

export default function ReportHeaderButtons({
  edit,
  changed,
  canSave,
  reportId,
  canWrite,
  keplerRef,
}) {
  const history = useHistory();

  const dispatch = useDispatch();

  const queries = useSelector((state) => state.queries);
  const query = useSelector((state) => state.activeQuery);
  const report = useSelector((state) => state.report);
  const queryStatus = useSelector((state) => state.queryStatus);
  const { canRun, queryText } = useSelector(
    (state) => !query ? true : state.queryStatus[query.id]
  );
  const keplerGl = useSelector((state) => state);
  const { kepler } = keplerGl;
  
  useEffect(() => {
    if (canRun && kepler) {
      setTimeout(() => {
        const gl = getGlobal("visStateActions");
        if (gl) {
          kepler.visState.filters.forEach((f, index) => {
            gl.setFilter(index, f, f, f.fieldIdx);
          });
        }
      }, 0);
    }
  }, [canRun, kepler?.uiState]);

  if (edit) {
    return (
      <div className={styles.reportHeaderButtons}>
        {canWrite ? (
          <>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              disabled={!canSave}
              onClick={() => dispatch(saveMap())}
            >
              Save{changed ? "*" : ""}
            </Button>
            <ForkButton reportId={reportId} disabled={!canSave} />
          </>
        ) : (
          <ForkButton reportId={reportId} primary disabled={!canSave} />
        )}
        <Button
          type="text"
          icon={<PlaySquareOutlined />}
          size="large"
          disabled={changed}
          title="Present Mode"
          onClick={() => history.replace(`/reports/${reportId}`)}
        />
        <CopyLinkButton />

        <TippyTooltip
          placement="top"
          render={() => <div id="action-re-run">Re-Run query</div>}
        >
          <Button
            disabled={!canRun}
            type="text"
            icon={<RedoOutlined />}
            size="large"
            onClick={() => {
              dispatch(runQuery(query.id, queryText));
            }}
          />
        </TippyTooltip>
        <LogoutButton />
      </div>
    );
  }
  if (canWrite) {
    return (
      <div className={styles.reportHeaderButtons}>
        <Button
          type="primary"
          disabled={!canWrite}
          icon={<EditOutlined />}
          onClick={() => history.replace(`/reports/${reportId}/source`)}
        >
          Edit
        </Button>
        <ForkButton reportId={reportId} disabled={!canSave} />
        <CopyLinkButton />

        <TippyTooltip
          placement="top"
          render={() => <div id="action-re-run">Re-Run query</div>}
        >
          <Button
            disabled={!canRun}
            type="text"
            icon={<RedoOutlined />}
            size="large"
            onClick={() => {
              dispatch(runQuery(query.id, queryText));
            }}
          />
        </TippyTooltip>
        <LogoutButton />
      </div>
    );
  }
  return (
    <div className={styles.reportHeaderButtons}>
      <ForkButton reportId={reportId} primary disabled={!canSave} />
      <Button
        type="text"
        icon={<ConsoleSqlOutlined />}
        onClick={() => history.replace(`/reports/${reportId}/source`)}
        title="View SQL source"
      />
      <CopyLinkButton />

      <TippyTooltip
        placement="top"
        render={() => <div id="action-re-run">Re-Run query</div>}
      >
        <Button
          disabled={!canRun}
          type="text"
          icon={<RedoOutlined />}
          size="large"
          onClick={() => {
            dispatch(runQuery(query.id, queryText));
          }}
        />
      </TippyTooltip>
      <LogoutButton />
    </div>
  );
}
