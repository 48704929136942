import React from "react";
import styles from './Header.module.css'
import { useSelector } from 'react-redux'
import DekartMenu from './DekartMenu'
import { getRef } from './lib/ref'


const LogoSvg = () => (
  <svg className="side-panel-logo__logo" width="100%" viewBox="0 0 792 689"> 
    <g>
      <path fill="#F7921E" d="M474.01,288.93v-64.97l-36.65,28.02L396,210.28l-41.36,41.7l-36.65-28.02v64.97l-0.02,0.02l0,43.62l7.86-7.82
        c0.29-0.29,23.57-23.15,49.92-32.37c-0.48,1.94-0.76,3.66-0.76,5.04c0,11.59,9.43,21.01,21.01,21.01
        c11.59,0,21.01-9.43,21.01-21.01c0-1.4-0.27-3.11-0.73-5.03c26.33,9.23,49.6,32.07,49.89,32.36l7.87,7.82v-43.62L474.01,288.93z
        M327.22,242.63l20.87,15.96l-20.87,21.04V242.63z M327.22,311.08v-18.35l28.26-28.49l23.02,17.6
        C357.21,287.65,337.67,302.22,327.22,311.08z M396,309.21c-6.5,0-11.87-5.29-11.79-11.79c0.03-2.08,1.23-5.55,1.26-5.65
        c1.58-4.88,9.6-22.45,10.54-24.35c0.82,1.84,10.33,23.63,10.55,24.32c0.12,0.38,1.25,2.96,1.22,5.68
        C407.72,303.93,402.5,309.21,396,309.21z M409.19,273.53c-3.77-8.28-7.76-15.82-9.12-18.36l-4.08-7.6l-4.05,7.61
        c-1.36,2.55-5.32,10.11-9.07,18.38l-20.83-15.93L396,223.38l33.97,34.25L409.19,273.53z M464.78,311.08
        c-10.45-8.86-29.99-23.43-51.28-29.24l23.02-17.6l28.26,28.49V311.08z M464.78,279.63l-20.87-21.04l20.87-15.96V279.63z"/>
      <g>
        <path fill="#FFFFFF" d="M102.78,379.74v4.78H61.75v16.12h-5.86v-37.99h47.64v4.88H61.75v12.21H102.78z"/>
        <path fill="#FFFFFF" d="M169.35,368.29c3.31,3.31,4.88,7.71,4.88,13.35c0,5.59-1.57,9.98-4.94,13.3c-3.58,3.64-10.75,6.78-24.91,6.78
          c-14.11,0-21.27-3.15-24.85-6.73c-3.36-3.36-4.94-7.76-4.94-13.35c0-5.64,1.57-9.98,4.94-13.35c3.64-3.64,10.74-6.73,24.85-6.73
          C158.55,361.56,165.72,364.65,169.35,368.29z M124.2,371.44c-2.44,2.44-3.69,5.43-3.69,10.2s1.3,7.71,3.74,10.15
          c2.77,2.77,8.85,5.21,20.13,5.21c11.34,0,17.37-2.33,20.24-5.21c2.33-2.33,3.69-5.37,3.69-10.15s-1.36-7.76-3.74-10.15
          c-2.88-2.88-8.85-5.21-20.19-5.21C133.1,366.28,127.08,368.56,124.2,371.44z"/>
        <path fill="#FFFFFF" d="M219.64,362.65c10.96,0,14.49,1.41,17.09,4.02c2.06,2.06,2.88,4.88,2.88,8.09c0,3.2-0.81,6.02-2.98,8.14
          c-2.28,2.28-5.7,4.02-14.6,4.29l22.2,13.29v0.16h-9.01l-22.09-13.46H194.3v13.46h-5.86v-37.99H219.64z M194.3,382.45h25.4
          c7.6,0,10.69-1.03,12.32-2.66c1.19-1.19,1.74-2.71,1.74-4.88c0-2.12-0.54-3.74-1.85-4.99c-1.63-1.63-4.45-2.39-12.21-2.39h-25.4
          V382.45z"/>
        <path fill="#FFFFFF" d="M305.91,367.53h-25.4v33.1h-5.92v-33.1h-25.4v-4.88h56.71V367.53z"/>
        <path fill="#FFFFFF" d="M345.94,394.77c-3.31-3.31-4.94-7.92-4.94-13.13c0-5.21,1.63-9.82,4.94-13.13c3.58-3.58,10.69-6.73,24.2-6.73
          c10.69,0,19.05,2.39,23.66,4.72v5.59c-5.43-3.04-14.22-5.48-23.61-5.48c-8.74,0-16.17,1.52-19.75,5.1
          c-2.33,2.33-3.53,5.64-3.53,9.93c0,4.29,1.19,7.49,3.53,9.88c3.58,3.58,11.02,5.16,19.7,5.16c9.44,0,18.23-2.33,23.66-5.21v5.32
          c-4.61,2.33-12.97,4.72-23.66,4.72C356.64,401.5,349.53,398.35,345.94,394.77z"/>
        <path fill="#FFFFFF" d="M437.86,362.43l27.4,38.2h-6.84l-6.78-9.55h-35.92l-6.73,9.55h-6.51l27.4-38.2H437.86z M419.14,386.25h29.09
          l-14.49-20.51L419.14,386.25z"/>
        <path fill="#FFFFFF" d="M507.09,362.65c10.96,0,14.76,1.52,17.42,4.18c2.12,2.12,2.82,4.99,2.82,8.19c0,3.26-0.76,6.08-2.93,8.25
          c-2.6,2.6-6.51,4.23-17.42,4.23h-24.69v13.13h-5.86v-37.99H507.09z M507.31,382.67c7.76,0,10.74-0.87,12.26-2.44
          c1.41-1.36,1.9-2.99,1.9-5.16c0-2.12-0.49-3.74-1.9-5.15c-1.52-1.52-4.5-2.44-12.26-2.44h-25.02v15.19H507.31z"/>
        <path fill="#FFFFFF" d="M540.99,400.63v-37.99h5.86v37.99H540.99z"/>
        <path fill="#FFFFFF" d="M616.47,367.53h-25.4v33.1h-5.92v-33.1h-25.4v-4.88h56.71V367.53z"/>
        <path fill="#FFFFFF" d="M651.19,362.43l27.4,38.2h-6.84l-6.78-9.55h-35.92l-6.73,9.55h-6.51l27.4-38.2H651.19z M632.47,386.25h29.09
          l-14.49-20.51L632.47,386.25z"/>
        <path fill="#FFFFFF" d="M736.11,395.69v4.94h-46.34v-37.99h5.86v33.05H736.11z"/>
      </g>
    </g>
  </svg>
)

export function Header ({ buttons, title }) {
  const env = useSelector(state => state.env)
  let homePage
  if (env.loaded) {
    homePage = env.variables.UX_HOMEPAGE + '?ref=' + getRef()
  }
  return (
    <div className={styles.header}>
      <DekartMenu />
      <div className={styles.middle}>
        {/* <div className={styles.dekartLinkHolder}><a target='_blank' rel='noopener noreferrer' className={styles.dekartLink} href={homePage}>Dekart</a></div> */}
        {/* <div className={styles.dekartLinkHolder}><LogoSvg/></div> */}
        <div className={styles.titleHolder}>{title ? (<div className={styles.title}>{title}</div>) : null}</div>
      </div>
      <div className={styles.buttons}>{buttons || null}</div>
    </div>
  )
}
