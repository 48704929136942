import { createIndexedDataContainer } from "kepler.gl/dist/utils/table-utils/data-container-utils";
import { gql } from "@apollo/client";

export const UPDATE_RESPONSE = `
  success
  message
  doc
`;

export const CREATE_CASE = gql`
   mutation createJob(
    $job: JobInput!
  ) {
    createJob(
      job: $job
    ) {
      ${UPDATE_RESPONSE}
    }
  }
`;

export function MapDataContainer(container, fields) {
  const flattened = container.flattenData();
  const mapped = [];

  for (let _row = 0; _row < flattened.length; _row++) {
    const row = flattened[_row];
    const obj = {};

    for (let _field = 0; _field < fields.length; _field++) {
      const field = fields[_field].name;
      obj[field] = row[_field];
    }

    mapped.push(obj);
  }

  return mapped;
}

export function getSelectedData(datasets, selectedDataset) {
  const selectedData = datasets[selectedDataset]
    ? datasets[selectedDataset]
    : Object.values(datasets);

  if (!selectedData) {
    return;
  }

  const { dataContainer, fields, label, filteredIdxCPU = [] } = selectedData;
  const container = createIndexedDataContainer(dataContainer, filteredIdxCPU);
  return MapDataContainer(container, fields);
}

function calculateDate(days) {
  const today = new Date();
  const result = new Date(today);
  result.setDate(today.getDate() + days);
  const day = String(result.getDate()).padStart(2, "0");
  const month = String(result.getMonth() + 1).padStart(2, "0");
  const year = result.getFullYear();
  const formattedDate = `${month}-${day}-${year}`;
  return formattedDate;
}

export function formatVariables(data) {
  
  return {
    variables: {
      job: {
        label: "New Deal",
        status: "open",
        job_type: "dc4c9d2cd5f554e5979f43ec7808a9e3",
        assigned_user: "0b782b2fbda84bf58b2f5c11b0b706d8",
        owner_user: "0b782b2fbda84bf58b2f5c11b0b706d8",
        priority: "low",
        due_date: calculateDate(0),
        values: [
          {
            uid: "485",
            control_value: data.map((r) => r.FOS_Property_Id).slice(0, 100),
          },
          {
            uid: "control-1677517112306",
            control_value: "Technology Team",
          },
          {
            uid: "484",
            control_value: "Yes",
          },
          {
            uid: "488",
            control_value: calculateDate(28),
          },
          {
            uid: "490",
            control_value: "Tracking",
          },
          {
            uid: "491",
            control_value: data.sort((a,b) => a.Deal_RBA - b.Deal_RBA)[0].True_Owner_Name
            ,
          },
          {
            uid: "492",
            control_value: data.sort((a,b) => a.Deal_RBA - b.Deal_RBA)[0].Address,
          },
        ],
        // values: {
        //   uid: "485",
        //   control_type: "multiReference",
        //   control_value: data.map((r) => r.FOS_Property_Id).slice(0, 100),
        // },
        // owner_user: '10c5bb6b5f534dbfb4a8e4a7178ab80c'
      },
    },
  };
}
