import React, { useEffect, useState, useRef } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  StreetViewPanorama,
  OverlayView,
} from "react-google-maps";

// https://react-google-maps-api-docs.netlify.app/#streetviewpanorama

const getValue = (data, fields, field_name) => {
  const fieldIdx = fields.findIndex((f) => f.name === field_name);
  if (fieldIdx < 0) return null;
  return data.valueAt(fieldIdx);
};

const observer = new PerformanceObserver((list) => {
  for (const entry of list.getEntries()) {
    console.log("Fetch request detected to", entry.name);
  }
});

observer.observe({
  entryTypes: ["resource"],
});

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});

const MapWithParanoma = withScriptjs(
  withGoogleMap((props) => {
    const { position } = props;
    return (
      <GoogleMap defaultZoom={8} defaultCenter={props.position}>
        <StreetViewPanorama
          position={props.position}
          defaultPosition={props.position}
          visible
          options={{
            addressControl: false,
            zoomControl: false,
            panControl: true,
            motionTrackingControl: false,
            enableCloseButton: false,
            enableAddressControl: true,
          }}
        ></StreetViewPanorama>
      </GoogleMap>
    );
  })
);

export default function ({ layerHoverProp, visible, hoverInfo }) {
  const [position, setPosition] = useState();
  const [map, setMap] = React.useState(null);
  const [pano, setChangePano] = React.useState(null);
  const [isRenderring, setRenderring] = React.useState(false);
  const [isShowMap, setShowMap] = React.useState(true);
  const timer = useRef(null);

  useEffect(() => {
    if (pano && !isRenderring) {
      setRenderring(true);
      setShowMap(false);
      timer.current = setTimeout(() => {
        setChangePano(false);
        setRenderring(false);
        setShowMap(true);
      }, 2000);
    }
  }, [pano]);

  useEffect(() => {
    if (layerHoverProp) {
      const { data, fields } = layerHoverProp;
      let lat, lng;

      if (data && fields) {
        lat = getValue(data, fields, "Latitude");
        lng = getValue(data, fields, "Longitude");
        if (
          getValue(data, fields, "SV_Longitude") &&
          getValue(data, fields, "SV_Latitude")
        ) {
          lat = getValue(data, fields, "SV_Latitude");
          lng = getValue(data, fields, "SV_Longitude");
        }
      }

      if (typeof lat !== "number" || typeof lng !== "number") {
        return;
      }

      if (
        hoverInfo &&
        hoverInfo.index !== -1 &&
        position &&
        position.lat !== parseFloat(lat) &&
        !isRenderring
      ) {
        var canvas = document.querySelector("#circle-example canvas");
        if (canvas) {
          var gl = canvas.getContext("webgl");
          gl.clearColor(0.0, 0.0, 0.0, 1.0);
          gl.clear(gl.COLOR_BUFFER_BIT);
        }
      }

      setTimeout(() => {
        setPosition({
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        });
      }, 0);
    }
  }, [layerHoverProp]);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);
  return (
    <MapWithParanoma
      position={position}
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDtOTVz8flgOICigg_6wOUjoOdGwW3JZtY&libraries=places"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={
        <div
          style={{
            width: "350px",
            height: "225px",
            backgroundColor: "#eeeeee",
            zIndex: 1000,
            position: "absolute",
            bottom: 10,
            right: 10,
            visibility: visible ? "visible" : "hidden",
          }}
        />
      }
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
}
