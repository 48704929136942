import { useParams } from "react-router-dom";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import React, { useEffect, useState, Component, useRef } from "react";
// import { KeplerGl } from 'kepler.gl'
import styles from "./ReportPage.module.css";
import { AutoSizer } from "react-virtualized";
import { useDispatch, useSelector } from "react-redux";
import {
  closeReport,
  openReport,
  createQuery,
  reportTitleChange,
  removeQuery,
  setActiveQuery,
  error,
} from "./actions";
import Query from "./Query";
import { EditOutlined, WarningFilled } from "@ant-design/icons";
import { Query as QueryType } from "../proto/dekart_pb";
import Tabs from "antd/es/tabs";
import { KeplerGlSchema } from "kepler.gl/schemas";
import classnames from "classnames";
import { Header } from "./Header";
import ReportHeaderButtons from "./ReportHeaderButtons";
import Downloading from "./Downloading";

import styled, { ThemeProvider } from "styled-components";

import {
  injectComponents,
  SidePanelFactory,
  ModalContainerFactory,
  LayerHoverInfoFactory,
  MapContainerFactory,
  InteractionPanelFactory,
  InteractionManagerFactory,
  TooltipChickletFactory,
  TooltipConfigFactory,
  GeocoderPanelFactory,
  MapLegendFactory,
  MapLegendPanelFactory,
} from "kepler.gl/components";
import { MapControlButton } from "kepler.gl/dist/components/common/styled-components";
import { ArrowLeft, ArrowRight } from "kepler.gl/dist/components/common/icons";

import CustomInteractionManager from "./custom/interaction-manager";
import CustomInteractionPanel from "./custom/interaction-panel";
import CustomSidePanel from "./custom/side-panel";
import CustomModalContainer from "./custom/modal-container";
import CustomLayerHoverInfo from "./custom/layer-hover-info";
import CustomMapContainer from "./custom/map-container";
import CustomTooltipChick from "./custom/tooltip-chicklet";
import CustomTooltipConfig from "./custom/tooltip-config";
import CustomGeocoderPanel from "./custom/geocoder-panel";
import CustomMapLegend from "./custom/map-legend";
import CustomMapLegendPanel from "./custom/map-legend-panel";

import Tippy, { TippyProps } from "@tippyjs/react";
import ReactDOM from "react-dom";

if (!("process" in window)) {
  // @ts-ignore
  window.process = {};
}

const TippyArrow = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  fill: ${(props) => props.theme.tooltipBg};
  text-align: initial;

  > svg {
    position: absolute;
  }
`;

const TippyTooltipContent = styled(({ children, ...props }) => (
  <div {...props}>
    {children}
    <TippyArrow className="svg-arrow" data-popper-arrow="">
      <svg width={14} height={14}>
        <path fill="hsl(220, 3%, 34%)" d="M2,7.5 7.5,2 13,7.5Z" />
      </svg>
    </TippyArrow>
  </div>
))`
  background-color: hsl(220, 3%, 34%);
  color: #ffffff;
  font-size: 10px;
  font-weight: 400;
  padding: 7px 18px;
  box-shadow: 0 1px 2px 0rgba (0, 0, 0, 0.1);
  border-radius: 3px;
  &[data-placement^="top"] > .svg-arrow {
    bottom: 0;
    &::after,
    > svg {
      top: 7px;
      transform: rotate(180deg);
    }
  }

  &[data-placement^="bottom"] > .svg-arrow {
    top: 0;
    > svg {
      bottom: 7px;
    }
  }

  &[data-placement^="left"] > .svg-arrow {
    right: 0;
    &::after,
    > svg {
      transform: rotate(90deg);
      left: 7px;
    }
  }

  &[data-placement^="right"] > .svg-arrow {
    left: 0;
    &::after,
    > svg {
      transform: rotate(-90deg);
      right: 7px;
    }
  }
`;

const TippyTooltip = ({
  children,
  render,
  duration = 200,
  ...rest
}: TippyProps) => {
  const [opacity, setOpacity] = useState(0);
  const [timer, setTimer] = useState(null);
  function onMount() {
    setOpacity(1);
    if (timer) {
      // @ts-ignore
      clearTimeout(timer);
    }
  }

  function onHide(instance) {
    const { unmount } = instance;
    const timeout = setTimeout(() => {
      if (!instance.state?.isDestroyed) {
        unmount();
      }
    }, duration[0] || duration);
    // @ts-ignore
    setTimer(timeout);
    setOpacity(0);
  }

  return (
    <Tippy
      {...rest}
      animation={true}
      render={(attrs) => (
        <TippyTooltipContent
          {...attrs}
          style={{ opacity, transition: `opacity ${duration}ms` }}
        >
          {render?.(attrs)}
        </TippyTooltipContent>
      )}
      onMount={onMount}
      onHide={onHide}
    >
      {children}
    </Tippy>
  );
};

function TabIcon({ query }) {
  let iconColor = "transparent";
  if (query.jobError) {
    iconColor = "#F66B55";
  }
  switch (query.jobStatus) {
    case QueryType.JobStatus.JOB_STATUS_RUNNING:
      iconColor = "#B8B8B8";
      break;
    case QueryType.JobStatus.JOB_STATUS_DONE:
      if (!query.jobResultId) {
        iconColor = "#B8B8B8";
        break;
      }
      iconColor = "#52c41a";
      break;
  }
  return (
    <span
      className={styles.tabIcon}
      style={{
        backgroundColor: iconColor,
      }}
    />
  );
}

function getOnTabEditHandler(dispatch, reportId) {
  return (queryId, action) => {
    switch (action) {
      case "add":
        return dispatch(createQuery(reportId));
      case "remove":
        Modal.confirm({
          title: "Are you sure delete query?",
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          onOk: () => dispatch(removeQuery(queryId)),
        });
    }
  };
}

function getTabPane(query, i, closable, changed) {
  return (
    <Tabs.TabPane
      tab={
        <>
          <TabIcon query={query} />
          {`Query ${i + 1}${changed ? "*" : ""}`}
        </>
      }
      key={query.id}
      closable={closable}
    />
  );
}

function QuerySection({ reportId }) {
  const queries = useSelector((state) => state.queries);
  const activeQuery = useSelector((state) => state.activeQuery);
  const report = useSelector((state) => state.report);
  const queryStatus = useSelector((state) => state.queryStatus);
  const { canWrite } = report;
  const dispatch = useDispatch();
  useEffect(() => {
    if (report && !activeQuery) {
      dispatch(createQuery(reportId));
    }
  }, [reportId, report, activeQuery, dispatch]);
  if (activeQuery) {
    const closable = queries.length > 1 && canWrite;
    return (
      <div className={styles.querySection}>
        <div className={styles.tabs}>
          <Tabs
            type="editable-card"
            activeKey={activeQuery.id}
            onChange={(queryId) => dispatch(setActiveQuery(queryId))}
            hideAdd={!canWrite}
            onEdit={getOnTabEditHandler(dispatch, reportId)}
          >
            {queries.map((query, i) =>
              getTabPane(query, i, closable, queryStatus[query.id].changed)
            )}
          </Tabs>
        </div>
        <Query query={activeQuery} key={activeQuery.id} />
      </div>
    );
  } else {
    return null;
  }
}

let checkMapConfigTimer;
function checkMapConfig(
  kepler,
  mapConfig,
  setMapChanged,
  isExpand,
  setExpand,
  isInit,
  setInit
) {
  if (checkMapConfigTimer) {
    clearTimeout(checkMapConfigTimer);
  }
  checkMapConfigTimer = setTimeout(() => {
    if (kepler) {
      const configToSave = JSON.stringify(
        KeplerGlSchema.getConfigToSave(kepler)
      );
      setMapChanged(configToSave !== mapConfig);
    }
    checkMapConfigTimer = null;
  }, 500);
  return () => {
    if (checkMapConfigTimer) {
      clearTimeout(checkMapConfigTimer);
    }
  };
}

function Title() {
  const reportStatus = useSelector((state) => state.reportStatus);
  const { canWrite } = useSelector((state) => state.report);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  if (canWrite && reportStatus.edit && edit) {
    return (
      <div className={styles.title}>
        <Input
          className={styles.titleInput}
          value={reportStatus.title}
          onChange={(e) => dispatch(reportTitleChange(e.target.value))}
          onBlur={() => setEdit(false)}
          placeholder="Untitled"
          autoFocus
          disabled={!(reportStatus.edit && canWrite)}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.title}>
        <span
          className={classnames(styles.titleText, {
            [styles.titleTextEdit]: reportStatus.edit && canWrite,
          })}
          onClick={() => reportStatus.edit && setEdit(true)}
        >
          {reportStatus.edit && canWrite ? (
            <EditOutlined className={styles.titleEditIcon} />
          ) : null}
          {reportStatus.title}
        </span>
      </div>
    );
  }
}

class CatchKeplerError extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    this.props.onError(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.keplerError}>
          <WarningFilled />
        </div>
      );
    }
    return this.props.children;
  }
}

const CustomState = {
  uiState: {
    currentModal: null,
  },
  mapState: {
    latitude: 32.741655,
    longitude: -97.337287,
    pitch: 60,
    bearing: 30,
    dragRotate: true,
    zoom: 12.5,
    maxPitch: 60,
    transitionDuration: 8000,
    // transitionInterpolator: new FlyToInterpolator()
  },
  visState: {
    layerBlending: "additive",
  },
  mapStyle: {
    styleType: "fortcapital",
    mapStyles: {
      // fortcapital: {
      //   id: 'fortcapital',
      //   label: 'Fort Capital',
      //   url: 'mapbox://styles/greg-from-the-fort/ck7xqrwrf01r11hmzxldt7jr4',
      // },
      // satellite: {
      //   id: 'satellite',
      //   label: 'Satellite',
      //   url: 'mapbox://styles/greg-from-the-fort/ck6lc47ts00wv1io8du9fh35g',
      // }
    },
  },
};

const fortOrange = "hsl(32, 83%, 53%)";
const fortGrey = "hsl(220, 3%, 34%)";

const customTheme = {
  // fontFamily: `'Roboto Condensed', sans-serif`,
  fontFamily: `'Lato', sans-serif`,
  sidePanelHeaderBg: fortGrey,
  tooltipBg: fortGrey,
  tooltipColor: "#ffffff",
  textColorHl: "#ffffff",
  dropdownListHighlightBg: fortOrange,
  switchTrackBgdActive: fortOrange,
  primaryBtnBgd: "hsl(32, 83%, 53%)",
  primaryBtnBgdHover: "hsl(32, 83%, 70%)",
};

const KeplerGl = injectComponents([
  [SidePanelFactory, CustomSidePanel],
  [ModalContainerFactory, CustomModalContainer],
  [MapContainerFactory, CustomMapContainer],
  [LayerHoverInfoFactory, CustomLayerHoverInfo],
  [TooltipChickletFactory, CustomTooltipChick],
  [TooltipConfigFactory, CustomTooltipConfig],
  [InteractionManagerFactory, CustomInteractionManager],
  [InteractionPanelFactory, CustomInteractionPanel],
  [GeocoderPanelFactory, CustomGeocoderPanel],
  [MapLegendFactory, CustomMapLegend],
]);

function Kepler({ expandBtn, mapConfig }) {
  const env = useSelector((state) => state.env);
  const dispatch = useDispatch();
  if (!env.loaded) {
    return (
      <div className={styles.keplerFlex}>
        <div className={styles.keplerBlock} />
      </div>
    );
  }

  const mapSetting = JSON.parse(mapConfig);
  let mapToken = null;
  if (mapSetting && mapSetting.config) {
    const mapStyles = mapSetting.config.mapStyle;
    if (
      mapStyles &&
      mapStyles.mapStyles &&
      mapStyles.mapStyles[mapStyles.styleType]
    ) {
      if (mapStyles.mapStyles[mapStyles.styleType].accessToken) {
        mapToken = mapStyles.mapStyles[mapStyles.styleType].accessToken;
      }
    }
  }

  return (
    <div className={styles.keplerFlex}>
      <div className={styles.keplerBlock}>
        <ThemeProvider theme={customTheme}>
          <AutoSizer>
            {({ height, width }) => (
              <CatchKeplerError onError={(err) => dispatch(error(err))}>
                <KeplerGl
                  id="kepler"
                  mapboxApiAccessToken={mapToken || env.variables.MAPBOX_TOKEN}
                  width={width}
                  height={height}
                  // mapstyles={CustomState.mapStyle.mapStyles}
                />
              </CatchKeplerError>
            )}
          </AutoSizer>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default function ReportPage({ edit }) {
  const { id } = useParams();
  const childRef = useRef(null);

  const kepler = useSelector((state) => state.keplerGl.kepler);
  const report = useSelector((state) => state.report);
  const envLoaded = useSelector((state) => state.env.loaded);
  const { mapConfig, title } = report || {};
  const reportStatus = useSelector((state) => state.reportStatus);
  const queryChanged = useSelector((state) =>
    Object.values(state.queryStatus).reduce((queryChanged, queryStatus) => {
      return queryStatus.changed || queryChanged;
    }, false)
  );

  const dispatch = useDispatch();

  const [mapChanged, setMapChanged] = useState(false);
  const [isExpand, setExpand] = useState(false);

  useEffect(() => {
    // make sure kepler loaded before firing kepler actions
    if (!envLoaded) {
      return;
    }
    dispatch(openReport(id, edit));
    return () => dispatch(closeReport(id));
  }, [id, dispatch, edit, envLoaded]);

  let checkMapReadyTimer;

  const expandFunc = () => {
    checkMapReadyTimer = setTimeout(() => {
      if (
        document.getElementsByClassName("map-control") &&
        document.getElementsByClassName("map-control")[0]
      ) {
        document
          .getElementsByClassName("map-control")[0]
          .insertAdjacentHTML("beforeend", `<div id="external-control"></div>`);
        ReactDOM.render(
          <TippyTooltip
            placement="left"
            render={() => (
              <div id="action-toggle">
                {isExpand ? "Collapse Query Panel" : "Expand Query Panel"}
              </div>
            )}
          >
            <button
              className={styles.expandButton}
              onClick={() => {
                if (isExpand) {
                  setExpand(false);
                } else {
                  setExpand(true);
                }
              }}
            >
              {isExpand ? <ArrowRight /> : <ArrowLeft />}
            </button>
          </TippyTooltip>,
          document.getElementById("external-control")
        );
        if (checkMapReadyTimer) {
          clearTimeout(checkMapReadyTimer);
        }
        checkMapReadyTimer = null;
      }
    }, 500);
  };
  useEffect(() => {
    expandFunc();
    return () => {
      if (checkMapReadyTimer) {
        clearTimeout(checkMapReadyTimer);
      }
    };
  }, [isExpand, mapConfig]);
  useEffect(() => {
    expandFunc();
  }, []);

  useEffect(
    () => checkMapConfig(kepler, mapConfig, setMapChanged, isExpand, setExpand),
    [kepler, mapConfig, setMapChanged, isExpand]
  );

  const titleChanged =
    reportStatus.title && title && reportStatus.title !== title;

  if (!report) {
    return null;
  }
  return (
    <div className={styles.report}>
      <Downloading />
      <Header
        title={<Title />}
        buttons={
          <ReportHeaderButtons
            reportId={id}
            canWrite={report.canWrite}
            changed={mapChanged || titleChanged || queryChanged}
            canSave={reportStatus.canSave}
            edit={edit}
            keplerRef={childRef}
          />
        }
      />
      <div className={styles.body}>
        <Kepler ref={childRef} mapConfig={mapConfig} />
        {edit && isExpand ? <QuerySection reportId={id} /> : null}
      </div>
    </div>
  );
}
