import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.less'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
// import { createLogger } from 'redux-logger'
import reducer from "./reducers";
import { taskMiddleware } from "react-palm/tasks";
import './styles.css'
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ApolloProvider,
  HttpLink
} from "@apollo/client";


const { REACT_APP_FORT_BASE_ENDPOINT } = process.env;
// import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from 'apollo-boost';

const httpLink = new HttpLink({ uri: REACT_APP_FORT_BASE_ENDPOINT });

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem('token');

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? token : null
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});


const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
  // headers: {
  //   authorization: localStorage.getItem('token'),
  // }
});

const store = createStore(
  reducer,
  compose(applyMiddleware(taskMiddleware, thunk /*, createLogger() */))
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
  ,
  document.getElementById('root')
)
