import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams,
} from "react-router-dom";
import ReportPage from "./ReportPage";
import HomePage from "./HomePage";
import { QuestionOutlined, WarningOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";
import Result from "antd/es/result";
import { useSelector, useDispatch } from "react-redux";
import { getEnv } from "./actions";
import sha256 from "sha256";
import nookies from "nookies";

import { callFOS } from "./lib/fos";
import { getToken } from "./lib/queries";
import {
  LoginPageV2,
  RefreshTokenWrapper,
} from "@fort-capital-default/fort-auth";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";

const TOKEN_KEY = "token";
const { REACT_APP_FORT_BASE_ENDPOINT } = process.env;
const httpLink = createHttpLink({
  uri: REACT_APP_FORT_BASE_ENDPOINT + "graphql",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = null;
  if (localStorage.getItem("token")) {
    token = localStorage.getItem("token");
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function AppRedirect() {
  const httpErrorStatus = useSelector((state) => state.httpErrorStatus);
  const { newReportId } = useSelector((state) => state.reportStatus);

  if (httpErrorStatus) {
    return <Redirect to={`/${httpErrorStatus}`} />;
  }

  if (newReportId) {
    return <Redirect to={`/reports/${newReportId}/source`} push />;
  }

  return null;
}

function RedirectToSource() {
  const { id } = useParams();
  return <Redirect to={`/reports/${id}/source`} />;
}

const ERRROR_CODE = {
  NOT_FOUND: "fc_not_found",
};

export default function App(props) {
  const env = useSelector((state) => state.env);
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const [currentUser, setCurrentUser] = useState({});
  const [isShowResetAuth, setShowRefreshAuth] = useState(false);

  const [form, setForm] = useState({
    company: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setToken(localStorage.getItem("token"));
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const expiredTime = localStorage.getItem("expire");
      if (expiredTime) {
        if (Number(expiredTime) <= new Date().getTime()) {
          const decodedJWT = JSON.parse(
            window.atob(localStorage.getItem("token").split(".")[1])
          );
          setCurrentUser({
            email: decodedJWT.email,
            company: decodedJWT.company,
          });
          setShowRefreshAuth(true);
          // localStorage.removeItem("expire");
          // localStorage.removeItem("token");;
          // window.location.reload();
        }
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const onCompleted = async (_data) => {
    if (!_data) {
      // setError("Error: please re-check your credentials.");
      return;
    }

    if (!_data.getToken) {
      // setError("Error: please re-check your credentials.");
      return;
    }

    if (_data.getToken === ERRROR_CODE.NOT_FOUND) {
      // setError("Error: please re-check your credentials.");
      return;
    }

    if (_data.getToken) {
      const decodedJWT = JSON.parse(window.atob(_data.getToken.split(".")[1]));
      if (decodedJWT && decodedJWT.exp) {
        const expires = decodedJWT.exp * 1000; //new Date().getTime() + (30 * 1000); 
        localStorage.setItem("expire", expires);
      }

      localStorage.setItem("token", _data.getToken);
      setToken(_data.getToken);
      setShowRefreshAuth(false)
    }
  };

  const onError = (props) => {
    // setError("Internal server error !");
  };

  const onCloseRefreshTokenModal = () => {};

  useEffect(() => {
    if (!env.loaded) {
      dispatch(getEnv());
    }
  });

  return (
    <div>
      {isShowResetAuth ? (
        <RefreshTokenWrapper
          onCloseModal={onCloseRefreshTokenModal}
          {...currentUser}
          endpoint={REACT_APP_FORT_BASE_ENDPOINT}
          onCompleted={onCompleted}
        />
      ) : (
        <></>
      )}
      {token ? (
        <Router>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/reports/:id/edit">
              <RedirectToSource />
            </Route>
            <Route path="/reports/:id/source">
              <ReportPage edit />
            </Route>
            <Route path="/reports/:id">
              <ReportPage />
            </Route>
            <Route path="/400">
              <Result
                icon={<WarningOutlined />}
                title="400"
                subTitle="Bad Request"
              />
            </Route>
            <Route path="/401">
              <Result
                icon={<WarningOutlined />}
                title="401"
                subTitle="Unauthorized"
              />
            </Route>
            <Route path="*">
              <Result
                icon={<QuestionOutlined />}
                title="404"
                subTitle="Page not found"
              />
            </Route>
          </Switch>
          <AppRedirect />
        </Router>
      ) : (
        // <ApolloProvider client={client}>
        <LoginPageV2 onCompleted={onCompleted} onError={onError} />
        // </ApolloProvider>
      )}
    </div>
  );
}
