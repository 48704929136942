import React from "react";

import Menu from 'antd/es/menu'
import styles from './DekartMenu.module.css'
import { useSelector } from 'react-redux'
import { getRef } from './lib/ref'

export default function DekartMenu () {
  const env = useSelector(state => state.env)
  if (!env.loaded) {
    return null
  }
  const ref = getRef()
  return (
    <div className={styles.dekartMenu}>
      <Menu mode='horizontal' theme='dark'>
        <Menu.Item key='reports'>
          <a href='/'>Reports</a>
        </Menu.Item>
      </Menu>
    </div>
  )
}
