// from: src/components/common/logo.tsx
import React from "react";
import {
  KEPLER_GL_NAME,
  KEPLER_GL_VERSION,
  KEPLER_GL_WEBSITE,
} from "kepler.gl/dist/constants/default-settings";
import { fortOrange, fortGrey } from "./defaults";
import { Reset } from "kepler.gl/dist/components/common/icons";
import { Tooltip } from "kepler.gl/dist/components/common/styled-components";
import { FormattedMessage } from "kepler.gl/dist/localization";
import Tippy, { TippyProps } from "@tippyjs/react";
import styled, { ThemeProvider } from "styled-components";
import { useEffect, useState, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelQuery, queryChanged, runQuery, showDataTable } from "../actions";
import { applyCPUFilter } from "kepler.gl/actions";

export const LogoTitle = styled.div`
  display: inline-block;
  margin-left: 6px;
`;

export const LogoName = styled.div`
  .logo__link {
    color: ${fortOrange};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.17px;
  }
`;

export const LogoVersion = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.subtextColor};
  letter-spacing: 0.83px;
  line-height: 14px;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const LogoSvgWrapper = styled.div`
  margin-top: 3px;
`;

const ButtonWrapper = styled.button`
  background: transparent;
  width: auto;
  position: absolute;
  right: 35px;
  top: 28px;
  color: #6a7485;
  &:hover {
    color: #fff;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

// export const LogoSvg = () => (
//   <svg className="side-panel-logo__logo" width="22px" height="15px" viewBox="0 0 22 15">
//     <g transform="translate(11, -3)">
//       <rect fill="#535C6C" x="0" y="5" width="10" height="10" />
//       <rect fill="#1FBAD6" x="5" y="0" width="10" height="10" />
//     </g>
//   </svg>
// );

// original viewbox: 0 0 792 612
// original fort grey = #1B3047
export const LogoSvg = () => (
  <svg
    viewBox="0.164 4.178 247.925 61.463"
    width="247.925px"
    height="61.463px"
    className="side-panel-logo__logo"
    style={{ padding: 10 }}
  >
    <rect
      id="backgroundrect"
      width="100%"
      height="100%"
      x="0"
      y="0"
      fill="none"
      stroke="none"
    />
    <g class="currentLayer">
      <title>Layer 1</title>
      <path
        fill="#EB8C23"
        d="M80.16714340209961,12.257360229492178 l-17.17,13.13 l-19.38,-19.54 l-19.38,19.54 l-17.17,-13.13 l-0.010000000000000002,50.89 l3.68,-3.66 c0.14,-0.14 11.04,-10.85 23.39,-15.17 c-0.22000000000000003,0.91 -0.35000000000000003,1.7200000000000002 -0.35000000000000003,2.36 c0,5.43 4.42,9.85 9.85,9.85 c5.43,0 9.84,-4.42 9.84,-9.85 c0,-0.6500000000000001 -0.13,-1.46 -0.3400000000000001,-2.36 c12.34,4.33 23.24,15.03 23.37,15.16 l3.6900000000000004,3.66 L80.16714340209961,12.257360229492178 zM11.38714340209961,21.00736022949218 l9.78,7.48 l-9.78,9.86 V21.00736022949218 zM11.38714340209961,53.0773602294922 v-8.6 l13.24,-13.35 l10.79,8.25 C25.447143402099613,42.09736022949218 16.287143402099616,48.927360229492194 11.38714340209961,53.0773602294922 M43.617143402099614,52.197360229492176 c-3.05,0 -5.5600000000000005,-2.48 -5.52,-5.52 c0.010000000000000002,-0.98 0.5800000000000001,-2.6 0.5900000000000001,-2.6500000000000004 c0.7400000000000001,-2.2800000000000002 4.5,-10.52 4.94,-11.41 c0.38000000000000006,0.8600000000000001 4.84,11.07 4.94,11.39 c0.06000000000000001,0.18000000000000002 0.5800000000000001,1.3900000000000001 0.5700000000000001,2.66 C49.10714340209961,49.72736022949218 46.66714340209961,52.197360229492176 43.617143402099614,52.197360229492176 M49.79714340209961,35.47736022949218 c-1.7600000000000002,-3.88 -3.63,-7.41 -4.27,-8.6 l-1.9100000000000001,-3.56 l-1.9,3.5700000000000003 c-0.6400000000000001,1.2 -2.49,4.74 -4.25,8.61 l-9.76,-7.46 l15.92,-16.05 l15.92,16.05 L49.79714340209961,35.47736022949218 zM75.84714340209962,53.0773602294922 c-4.890000000000001,-4.15 -14.05,-10.98 -24.03,-13.7 l10.79,-8.25 l13.24,13.35 V53.0773602294922 zM75.84714340209962,38.33736022949219 l-9.78,-9.86 l9.78,-7.48 V38.33736022949219 z"
        id="svg_1"
      />
      <g id="svg_2">
        <path
          fill="#FFFFFF"
          d="M136.7771434020996,25.00736022949218 c0,-3.8600000000000003 1.17,-6.95 3.51,-9.27 c2.34,-2.3200000000000003 5.82,-3.48 10.43,-3.48 c4.61,0 8.1,1.17 10.47,3.51 c2.37,2.34 3.55,5.42 3.55,9.23 v20.8 c0,3.81 -1.19,6.87 -3.55,9.19 c-2.37,2.3200000000000003 -5.85,3.48 -10.43,3.48 c-4.58,0 -8.06,-1.1600000000000001 -10.43,-3.48 c-2.37,-2.3200000000000003 -3.55,-5.38 -3.55,-9.19 V25.00736022949218 zM157.4771434020996,25.237360229492197 c0,-4.22 -2.24,-6.33 -6.72,-6.33 c-4.48,0 -6.72,2.11 -6.72,6.33 V45.49736022949219 c0,4.27 2.24,6.41 6.72,6.41 c4.48,0 6.72,-2.14 6.72,-6.41 V25.237360229492197 z"
          id="svg_3"
        />
        <path
          fill="#FFFFFF"
          d="M221.3571434020996,61.36736022949219 c2.49,0.52 4.93,1.09 7.34,1.6900000000000002 v-44.38 h9.96 v-6.41 h-27.27 v6.41 h9.96 V61.36736022949219 z"
          id="svg_4"
        />
        <path
          fill="#FFFFFF"
          d="M112.43714340209962,61.36736022949219 v-20.11 h11.58 v-6.49 h-11.58 v-16.1 h15.84 v-6.41 h-23.17 v50.8 C107.5571434020996,62.407360229492184 109.4971434020996,62.02736022949219 112.43714340209962,61.36736022949219 z"
          id="svg_5"
        />
        <path
          fill="#FFFFFF"
          d="M196.8671434020996,40.00736022949218 c4.38,-1.6500000000000001 6.57,-5.33 6.57,-11.05 v-5.04 c0,-3.66 -1.1,-6.51 -3.2800000000000002,-8.57 c-2.19,-2.06 -5.34,-3.09 -9.46,-3.09 h-14.06 V56.49736022949219 c2.44,0.04000000000000001 4.86,0.12000000000000001 7.26,0.23 v-15.5 h5.9 l6,16.33 c2.8,0.26 5.57,0.56 8.3,0.91 L196.8671434020996,40.00736022949218 zM196.1771434020996,28.887360229492174 c0,2.01 -0.48000000000000004,3.5 -1.4300000000000002,4.48 c-0.7500000000000001,0.77 -1.8800000000000001,1.23 -3.38,1.4 h-7.48 V18.597360229492182 h6.18 c4.07,0 6.1,1.9300000000000002 6.1,5.79 V28.887360229492174 z"
          id="svg_6"
        />
      </g>
    </g>
  </svg>

  // <svg className="side-panel-logo__logo" width="100%" viewBox="0 200 792 200">
  //   <g>
  //     <path
  //       fill="#F7921E"
  //       d="M474.01,288.93v-64.97l-36.65,28.02L396,210.28l-41.36,41.7l-36.65-28.02v64.97l-0.02,0.02l0,43.62l7.86-7.82
  //       c0.29-0.29,23.57-23.15,49.92-32.37c-0.48,1.94-0.76,3.66-0.76,5.04c0,11.59,9.43,21.01,21.01,21.01
  //       c11.59,0,21.01-9.43,21.01-21.01c0-1.4-0.27-3.11-0.73-5.03c26.33,9.23,49.6,32.07,49.89,32.36l7.87,7.82v-43.62L474.01,288.93z
  //       M327.22,242.63l20.87,15.96l-20.87,21.04V242.63z M327.22,311.08v-18.35l28.26-28.49l23.02,17.6
  //       C357.21,287.65,337.67,302.22,327.22,311.08z M396,309.21c-6.5,0-11.87-5.29-11.79-11.79c0.03-2.08,1.23-5.55,1.26-5.65
  //       c1.58-4.88,9.6-22.45,10.54-24.35c0.82,1.84,10.33,23.63,10.55,24.32c0.12,0.38,1.25,2.96,1.22,5.68
  //       C407.72,303.93,402.5,309.21,396,309.21z M409.19,273.53c-3.77-8.28-7.76-15.82-9.12-18.36l-4.08-7.6l-4.05,7.61
  //       c-1.36,2.55-5.32,10.11-9.07,18.38l-20.83-15.93L396,223.38l33.97,34.25L409.19,273.53z M464.78,311.08
  //       c-10.45-8.86-29.99-23.43-51.28-29.24l23.02-17.6l28.26,28.49V311.08z M464.78,279.63l-20.87-21.04l20.87-15.96V279.63z"
  //     />
  //     <g>
  //       <path
  //         fill="#FFFFFF"
  //         d="M102.78,379.74v4.78H61.75v16.12h-5.86v-37.99h47.64v4.88H61.75v12.21H102.78z"
  //       />
  //       <path
  //         fill="#FFFFFF"
  //         d="M169.35,368.29c3.31,3.31,4.88,7.71,4.88,13.35c0,5.59-1.57,9.98-4.94,13.3c-3.58,3.64-10.75,6.78-24.91,6.78
  //         c-14.11,0-21.27-3.15-24.85-6.73c-3.36-3.36-4.94-7.76-4.94-13.35c0-5.64,1.57-9.98,4.94-13.35c3.64-3.64,10.74-6.73,24.85-6.73
  //         C158.55,361.56,165.72,364.65,169.35,368.29z M124.2,371.44c-2.44,2.44-3.69,5.43-3.69,10.2s1.3,7.71,3.74,10.15
  //         c2.77,2.77,8.85,5.21,20.13,5.21c11.34,0,17.37-2.33,20.24-5.21c2.33-2.33,3.69-5.37,3.69-10.15s-1.36-7.76-3.74-10.15
  //         c-2.88-2.88-8.85-5.21-20.19-5.21C133.1,366.28,127.08,368.56,124.2,371.44z"
  //       />
  //       <path
  //         fill="#FFFFFF"
  //         d="M219.64,362.65c10.96,0,14.49,1.41,17.09,4.02c2.06,2.06,2.88,4.88,2.88,8.09c0,3.2-0.81,6.02-2.98,8.14
  //         c-2.28,2.28-5.7,4.02-14.6,4.29l22.2,13.29v0.16h-9.01l-22.09-13.46H194.3v13.46h-5.86v-37.99H219.64z M194.3,382.45h25.4
  //         c7.6,0,10.69-1.03,12.32-2.66c1.19-1.19,1.74-2.71,1.74-4.88c0-2.12-0.54-3.74-1.85-4.99c-1.63-1.63-4.45-2.39-12.21-2.39h-25.4
  //         V382.45z"
  //       />
  //       <path
  //         fill="#FFFFFF"
  //         d="M305.91,367.53h-25.4v33.1h-5.92v-33.1h-25.4v-4.88h56.71V367.53z"
  //       />
  //       <path
  //         fill="#FFFFFF"
  //         d="M345.94,394.77c-3.31-3.31-4.94-7.92-4.94-13.13c0-5.21,1.63-9.82,4.94-13.13c3.58-3.58,10.69-6.73,24.2-6.73
  //         c10.69,0,19.05,2.39,23.66,4.72v5.59c-5.43-3.04-14.22-5.48-23.61-5.48c-8.74,0-16.17,1.52-19.75,5.1
  //         c-2.33,2.33-3.53,5.64-3.53,9.93c0,4.29,1.19,7.49,3.53,9.88c3.58,3.58,11.02,5.16,19.7,5.16c9.44,0,18.23-2.33,23.66-5.21v5.32
  //         c-4.61,2.33-12.97,4.72-23.66,4.72C356.64,401.5,349.53,398.35,345.94,394.77z"
  //       />
  //       <path
  //         fill="#FFFFFF"
  //         d="M437.86,362.43l27.4,38.2h-6.84l-6.78-9.55h-35.92l-6.73,9.55h-6.51l27.4-38.2H437.86z M419.14,386.25h29.09
  //         l-14.49-20.51L419.14,386.25z"
  //       />
  //       <path
  //         fill="#FFFFFF"
  //         d="M507.09,362.65c10.96,0,14.76,1.52,17.42,4.18c2.12,2.12,2.82,4.99,2.82,8.19c0,3.26-0.76,6.08-2.93,8.25
  //         c-2.6,2.6-6.51,4.23-17.42,4.23h-24.69v13.13h-5.86v-37.99H507.09z M507.31,382.67c7.76,0,10.74-0.87,12.26-2.44
  //         c1.41-1.36,1.9-2.99,1.9-5.16c0-2.12-0.49-3.74-1.9-5.15c-1.52-1.52-4.5-2.44-12.26-2.44h-25.02v15.19H507.31z"
  //       />
  //       <path fill="#FFFFFF" d="M540.99,400.63v-37.99h5.86v37.99H540.99z" />
  //       <path
  //         fill="#FFFFFF"
  //         d="M616.47,367.53h-25.4v33.1h-5.92v-33.1h-25.4v-4.88h56.71V367.53z"
  //       />
  //       <path
  //         fill="#FFFFFF"
  //         d="M651.19,362.43l27.4,38.2h-6.84l-6.78-9.55h-35.92l-6.73,9.55h-6.51l27.4-38.2H651.19z M632.47,386.25h29.09
  //         l-14.49-20.51L632.47,386.25z"
  //       />
  //       <path
  //         fill="#FFFFFF"
  //         d="M736.11,395.69v4.94h-46.34v-37.99h5.86v33.05H736.11z"
  //       />
  //     </g>
  //   </g>
  // </svg>
);

interface KeplerGlLogoProps {
  appName?: string;
  version?: string | boolean;
  appWebsite?: string;
  visStateActions?: any;
  uiStateActions?: any;
}

const KeplerGlLogo = ({
  appName = KEPLER_GL_NAME,
  appWebsite = KEPLER_GL_WEBSITE,
  version = KEPLER_GL_VERSION,
  visStateActions,
  uiStateActions,
}: KeplerGlLogoProps) => {
  return (
    <LogoWrapper className="side-panel-logo">
      <LogoSvgWrapper>
        {/*        
        , */}
        <LogoSvg />
      </LogoSvgWrapper>
      {/* <LogoTitle className="logo__title">
      <LogoName className="logo__name">
        <a className="logo__link" target="_blank" rel="noopener noreferrer" href="https://fortoperatingsystem.com">
          Fort Geo
        </a>
      </LogoName>
      {version ? <LogoVersion className="logo__version">{version}</LogoVersion> : null}
    </LogoTitle> */}
    </LogoWrapper>
  );
};

export default KeplerGlLogo;
